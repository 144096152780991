<template>
  <div class="container custom-scroller">
    <div class="answerSheetContainer custom-scroller"
         ref="questionnaireContent"
         @scroll="showTipBottomVeil = $event.target.scrollTop == 0 ? false : true"
    >
      <el-collapse v-if="groupAnswerSheets.length" v-model="previewActiveGroups" class="groupQuestionCollapse">

        <el-collapse-item  v-for="(group,index) in groupWithUnassigned" :key="group.id" :title="group.name" :name="group.id" v-show="groupVisible[index]">
          <template #title>
            <img src="../../../assets/images/groupMenu.png" class="groupMenuIcon"/>{{group.name}}
          </template>
          <doc-question-answer-mode v-for="(answer,j)  in groupAnswerSheets[index]" :key="j"
                                    v-show="groupAnswerSheetVisible[index][j]"
                                    :isPreview="true"
                                    :answerSheet="answer" :autoSave="false" :canSearch="false"
                                    :showVariable="true">
          </doc-question-answer-mode>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import DocQuestionAnswerMode from "../../../components/DocQuestionAnswerMode";
import {getDefaultAnswerSheetInfo, requestHeaderMixin} from "../../../api/api";
import {
  answerSheetShouldShowQuestion,
  fixAnswerContent,
  fixOrganizationQuestionIndex,
  getAnswerCacheMap, getOrProduceQuestionDefaultAnswer, getQuestionAnswerCacheKey, produceAnswer, questionStrProp2Obj
} from "../../../constant/question";
import {colorLog, itemJsonStrPro2Obj} from "../../../util";

export default {
  name: "TemplatePreviewQuestions",
  components: {DocQuestionAnswerMode},
  mixins:[requestHeaderMixin],
  mounted() {
    this.createAnswerSheet();
  },
  data(){
    return {
      showTipBottomVeil:false,
      groups:[],
      previewActiveGroups: [],
      answerSheet: [],
      groupVisible:[],
      groupAnswerSheetVisible:[],
      fileId:this.$route.query.fileId,
    }
  },
  computed:{
    groupWithUnassigned: function () {
      return  Array.from(this.groups).concat({id:-1,name:'未分组'});
    },
    answerSheetMap: function () {
      return new Map(this.answerSheet.map(a => [a.id, a]))
    },
    groupAnswerSheets: function () {
      const groupAnswerSheetMap = new Map();
      const answerSheets = this.answerSheet
      for (let i = 0; i < answerSheets.length; i++) {
        let answerSheet = answerSheets[i];
        const groupId = answerSheet.question.groupId?answerSheet.question.groupId:-1;
        if (!groupAnswerSheetMap.has(groupId)) {
          groupAnswerSheetMap.set(groupId, []);
        }
        groupAnswerSheetMap.get(groupId).push(answerSheet);
      }
      return this.groupWithUnassigned.map(group=>{
        const values = groupAnswerSheetMap.get(group.id)
        return values ? values : [];
      })
    },
  },
  methods:{
    /**
     * 创建答卷
     */
    createAnswerSheet: function () {
      const self = this;
      //获取缓存的答卷信息
      return getDefaultAnswerSheetInfo(this.fileId).then(res=>{
        this.groups = res.data.data.groups;
        const answerSheetInfo = res.data.data
        //获取到所有问题的答案缓存
        let answers = answerSheetInfo.answers
        // 如果是起草合同模块，则不需要默认的answers
        if (this.draftContractMode) {
          answers = [];
        }
        const answerSheetId = answerSheetInfo.sheet.id;
        const questionAnswerMap = getAnswerCacheMap(answers);
        const questions = fixOrganizationQuestionIndex(res.data.data.questions);
        this.translateQuestions(questions);
        colorLog.green("create AnswerSheet******",questions);
        self.answerSheet = questions.map((item) => {
          let answer = questionAnswerMap.get(getQuestionAnswerCacheKey(item.id, item.answerType));
          if (answer) {
            itemJsonStrPro2Obj(answer,'content');
            if (typeof answer.content === "number") {
              answer.content = JSON.stringify(answer.content);
            }
            // self.fixDefaultValueIfNeed(item,answer);
            fixAnswerContent(item,answer);
            // 设置默认答案。（如果切换到预览模式之后设置的默认答案，就需要将默认答案设置到答案里面）
            if ((answer.content == null || answer.content === '') && item.defaultAnswer != null) {
              answer.content = getOrProduceQuestionDefaultAnswer(item, true);
            }
          }else {
            answer = produceAnswer(item.answerType, getOrProduceQuestionDefaultAnswer(item,true), item.id, answerSheetId,self.fileId);
          }
          return {
            question: item,
            questionUUID:item.uuid,
            answer: answer
          };
        });
        self.previewActiveGroups = this.groupWithUnassigned.map(group => group.id);
        this.reCalculateGroupAnswerSheetVisible();
      })
    },

    translateQuestions: function(questions) {
      for (let i = 0; i < questions.length; i++) {
        //将json字符串属性解析为对象
        questionStrProp2Obj(questions[i]);
        // 将老数据中的options转换为新结构
        if(questions[i].options){
          questions[i].options = questions[i].options.map(option => {
            if (typeof option === "object") {
              return option
            }
            return {id: option, name: option, desc: ''};
          });
        }
      }
    },

    /**
     * 重新计算那个问题该显示，哪个问题该隐藏
     */
    reCalculateGroupAnswerSheetVisible: function () {
      let sheetSize = this.groupAnswerSheets.length;
      let groupAnswerSheetVisible = [];
      let groupVisible = [];
      for (let i = 0; i < sheetSize; i++) {
        let groupAnswerSheet = this.groupAnswerSheets[i];
        let groupQuestionSize = groupAnswerSheet.length;
        groupAnswerSheetVisible.push([]);
        let currentGroupVisible = false;
        for (let j = 0; j < groupQuestionSize; j++) {
          let answerSheet = this.groupAnswerSheets[i][j];
          let questionVisible = this.showQuestion(answerSheet);
          groupAnswerSheetVisible[i].push(questionVisible);
          if (questionVisible) {
            currentGroupVisible = true;
          }
        }
        groupVisible.push(currentGroupVisible);
      }
      this.groupAnswerSheetVisible = groupAnswerSheetVisible;
      this.groupVisible = groupVisible;
    },

    showQuestion:function(answerSheet){
      // console.log('************new show question*****')

      return answerSheetShouldShowQuestion(answerSheet, this.answerSheetMap);
    },
  }
}
</script>

<style scoped>
.answerSheetContainer{
  z-index:11;
  overflow-y:auto;
  padding:0px 10px 10px 10px;
  background: #fff;
  scroll-width:thin;
}
</style>
